import React from "react";
import logo from "../assets/logo.svg";
import { fabric } from "fabric";
import { DEFAULT_CANVAS_DIMENSION } from "./Poster";
import { lockedObject } from "../utils/createLockedObject";

interface Props {
  canvas: fabric.Canvas | null;
}

// https://codesandbox.io/s/8k7kvwqx70?file=/src/Image.js:410-453
export const Logo: React.FC<Props> = ({ canvas }) => {
  const height = (canvas?.height || DEFAULT_CANVAS_DIMENSION.height) / 4;

  React.useEffect(() => {
    if (!canvas) return;
    fabric.Image.fromURL(
      logo,
      (img) => {
        img.scaleToHeight(height);
        canvas.add(img);
        canvas.bringToFront(img);
      },
      {
        ...lockedObject,
        top: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.95 - height,
        left: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) / 15,
      }
    );
  }, [canvas, height]);

  return null;
};
