import React from "react";
import { fabric } from "fabric";
import { DEFAULT_CANVAS_DIMENSION } from "./Poster";
import { lockedObject } from "../utils/createLockedObject";

interface Props {
  canvas: fabric.Canvas | null;
}

// https://codesandbox.io/s/8k7kvwqx70?file=/src/Image.js:410-453
export const BottomText: React.FC<Props> = ({ canvas }) => {
  React.useEffect(() => {
    if (!canvas) return;

    const textBoxDefaults = {
      ...lockedObject,
      left: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) * 0.45,
      width: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) * 0.5,
      fontFamily: "Roboto Condensed",
      fontSize: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) / 27,
      lineHeight: 1.1,
      textAlign: "justify",
      fontWeight: 800,
      charSpacing: +75,
    };
    const baseHeight =
      (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.83;
    const line = textBoxDefaults.fontSize * 1.15;
    const fabricTextBox1 = new fabric.Textbox("Hilarische filmpjes", {
      ...textBoxDefaults,
      // @ts-ignore
      id: "bottom_text_1",
      top: baseHeight,
      charSpacing: +75,
    });
    const fabricTextBox2 = new fabric.Textbox("Creatieve quizvragen", {
      ...textBoxDefaults,
      // @ts-ignore
      id: "bottom_text_2",
      fontSize: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) / 30,
      top: baseHeight + line,
    });
    const fabricTextBox3 = new fabric.Textbox("Grote dosis humor", {
      ...textBoxDefaults,
      // @ts-ignore
      id: "bottom_text_3",
      fontSize: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) / 26,
      top: baseHeight + line * 1.9,
    });
    const group = new fabric.Group([
      fabricTextBox1,
      fabricTextBox2,
      fabricTextBox3,
    ]);
    canvas.add(group);
    canvas.bringToFront(group);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  return null;
};
