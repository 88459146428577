import React from "react";
import cn from "classnames";
import logo from "./assets/logo.svg";
import { Poster } from "./Poster/Poster";
var FontFaceObserver = require("fontfaceobserver");

export const App: React.FC = () => {
  const [loading, setLoading] = React.useState(true);

  const waitForFonts = async () => {
    const openSans = new FontFaceObserver("Open Sans");
    const montSerrat = new FontFaceObserver("Montserrat");
    const roboto = new FontFaceObserver("Roboto Condensed");
    await Promise.all([openSans.load(), montSerrat.load(), roboto.load()]);
    setLoading(false);
  };

  React.useEffect(() => {
    waitForFonts();
  }, []);

  return (
    <div
      className={cn(
        "bg-gradient-to-tl",
        "from-sky-800",
        "via-violet-400",
        "to-indigo-200",
        "pt-2",
        "md:pt-8",
        "px-2",
        "md:px-8",
        "min-h-screen",
        "flex",
        "justify-center"
      )}
    >
      <div
        className={cn(
          "w-full",
          "max-w-5xl",
          "grow",
          "flex",
          "flex-wrap",
          "flex-col"
        )}
      >
        <div
          className={cn(
            "w-full",
            "text-2xl",
            "uppercase",
            "mb-4",
            "flex",
            "items-end"
          )}
        >
          <img src={logo} alt="De andere quiz" className="w-14 h-20 mb-1" />{" "}
          <div className="ml-5">Poster app</div>
        </div>
        <div
          className={cn(
            "w-full",
            "grow",
            "bg-white",
            "rounded-t-lg",
            "p-4",
            "md:p-8"
          )}
        >
          {!loading && <Poster />}
        </div>
      </div>
    </div>
  );
};
