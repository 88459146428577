import { fabric } from "fabric";
import React from "react";
import { BackGround } from "./BackGround";
import { BottomText } from "./BottomText";
import { Flag } from "./Flag";
import { Logo } from "./Logo";
import { SpeechBubble } from "./SpeechBubble";
import { TitleText } from "./TitleText";

export const DEFAULT_CANVAS_DIMENSION = {
  height: 600,
  width: 400,
};

export const Poster: React.FC = () => {
  const [canvas, setCanvas] = React.useState<fabric.Canvas | null>(null);
  const [title] = React.useState("SPEEL MEE!");
  const [speech, setSpeech] = React.useState(
    "Kom je zaterdag 29 mei? Het kost 10 euro per team"
  );
  React.useEffect(() => {
    setCanvas(new fabric.Canvas("canvas1", DEFAULT_CANVAS_DIMENSION));
  }, []);

  React.useEffect(() => {
    if (!canvas) {
      return;
    }
    // const newWidth = getMaxWidthHeight();
    // const width = canvas.getWidth();
    // const height = canvas.getHeight();
    // const zoom = newWidth / width;
    // canvas.setZoom(zoom);
    // canvas.setWidth(width * zoom);
    // canvas.setHeight(height * zoom);
    // window.addEventListener(
    //   "resize",
    //   debounce(() => {
    //     // const cont = document.querySelector(".canvas-container") as HTMLElement;
    //     // const upper = document.querySelector(".upper-canvas") as HTMLElement;
    //     // if (cont) {
    //     //   cont.style.width = String(width * zoom);
    //     //   cont.style.height = String(height * zoom);
    //     // }
    //     // if (upper) {
    //     //   upper.style.width = String(width * zoom);
    //     //   upper.style.height = String(height * zoom);
    //     // }
    //   })
    // );
  }, [canvas]);

  // const getMaxWidthHeight = (): number => {
  //   const parent = document.querySelector(
  //     ".canvas-parent"
  //   ) as HTMLElement | null;
  //   const parentWidth = parent?.offsetWidth || 0 ;
  //   let { width } = DEFAULT_CANVAS_DIMENSION;

  //   if (parentWidth && parentWidth < width) {
  //     width = parentWidth;
  //   }
  //   return width;
  // };

  const save = () => {
    const c = document.getElementById("canvas1") as HTMLCanvasElement;
    if (!c) {
      return;
    }
    const image = c.toDataURL("image/jpeg", 1.0);
    var a = document.createElement("a");
    a.href = image;
    a.download = "poster_de_andere_quiz";
    document.body.appendChild(a);
    a.click();
  };

  return (
    <div className="md:grid md:grid-cols-1 md:grid-cols-3 md:gap-4 lg:gap-8">
      <div>
        <div className="pb-4">
          <h5>Welkom!</h5>
          <p>
            Vul hieronder de tekst in voor de poster en klik op download poster.
          </p>
          {/* <label htmlFor="title">Titel</label>
          <input
          id="title"
            type={"text"}
            className={"w-full p-3"}
            onChange={(e) => setTitle(e.target.value || "")}
            value={title}
          /> */}
        </div>
        <hr className="pb-4" />
        <div className="pb-4">
          <label htmlFor="balloon">Tekst</label>
          <textarea
            id="balloon"
            className={"w-full p-3"}
            onChange={(e) => setSpeech(e.target.value || "")}
            value={speech}
          />
        </div>
        <button
          onClick={save}
          type={"button"}
          className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
        >
          <svg
            className="fill-current w-4 h-4 mr-2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z" />
          </svg>
          <span>Download poster</span>
        </button>
      </div>
      <div className="mt-4 md:mt-0 md:col-start-2 md:col-end-4 canvas-parent">
        <div className="scale-75 translate-y-[-12.5%] translate-x-[-12.5%] md:transform-none">
          <canvas id="canvas1" />
        </div>
      </div>
      <BackGround canvas={canvas} />
      <Logo canvas={canvas} />
      <BottomText canvas={canvas} />
      <Flag canvas={canvas} text={title} />
      <TitleText canvas={canvas} text={title} />
      <SpeechBubble canvas={canvas} text={speech} />
    </div>
  );
};
