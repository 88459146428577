import React from "react";
import { fabric } from "fabric";
import { DEFAULT_CANVAS_DIMENSION } from "./Poster";
import { lockedObject } from "../utils/createLockedObject";
import { getCanvasObjectById } from "../utils/getObject";

interface Props {
  text: string;
  canvas: fabric.Canvas | null;
}

// https://codesandbox.io/s/8k7kvwqx70?file=/src/Image.js:410-453
export const TitleText: React.FC<Props> = ({ text, canvas }) => {
  React.useEffect(() => {
    if (!canvas) return;

    const fabricTextBox = new fabric.Textbox(text, {
      ...lockedObject,
      // @ts-ignore
      id: "title_text",
      top: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.05,
      left: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) * 0.05,
      width: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) * 0.9,
      fontFamily: "Open Sans",
      fontSize: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) / 12,
      textAlign: "center",
      fill: "white",
    });
    canvas.add(fabricTextBox);
    canvas.bringToFront(fabricTextBox);
    canvas.requestRenderAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  React.useEffect(() => {
    if (!canvas) return;

    const object = getCanvasObjectById("title_text", canvas);
    if (object) {
      object._set("text", text.trim());
      canvas?.renderAll();
    }
  }, [canvas, text]);

  return null;
};
