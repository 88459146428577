import { fabric } from "fabric";

export const lockedObject: fabric.IObjectOptions = {
  hasControls: false,
  lockMovementX: true,
  lockMovementY: true,
  lockScalingX: true,
  lockRotation: true,
  lockScalingFlip: true,
  lockScalingY: true,
  lockSkewingX: true,
  lockSkewingY: true,
};
