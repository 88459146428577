import { fabric } from "fabric";

export type PosterObjectIdText = "title_text" | "speech_text";
export type PosterObjectIdAll = PosterObjectIdText | "flag_group" | "speech_bubble";

export const getCanvasObjectById = (
  id: PosterObjectIdAll,
  canvas: fabric.Canvas | null
): fabric.Object | undefined => {
  return (
    canvas
      ?.getObjects()
      // @ts-ignore
      .find((obj) => obj.id === id)
  );
};
