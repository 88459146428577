import { fabric } from "fabric";
import React from "react";
import { getCanvasObjectById } from "../utils/getObject";
import { DEFAULT_CANVAS_DIMENSION } from "./Poster";

interface Props {
  text: string;
  canvas: fabric.Canvas | null;
}

// https://codesandbox.io/s/8k7kvwqx70?file=/src/Image.js:410-453
export const SpeechBubble: React.FC<Props> = ({ text, canvas }) => {
  const bubbleHeightFactor = 1.3;

  React.useEffect(() => {
    if (!canvas) return;
    const top = (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.5;
    const left = (canvas.width || DEFAULT_CANVAS_DIMENSION.width) * 0.4;
    const speech = new fabric.Textbox(text, {
      // @ts-ignore
      id: "speech_text",
      fontSize: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) / 35,
      fill: "black",
      originX: "center",
      originY: "center",
      fontFamily: "Open Sans",
      top,
      left,
      width: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) * 0.65,
    });
    const height = speech.getScaledHeight();
    const width = speech.getScaledWidth();

    const bubble = new fabric.Rect({
      // @ts-ignore
      id: "speech_bubble",
      fill: "rgba(255,255,255,.75)",
      originX: "center",
      originY: "center",
      rx: 15,
      ry: 15,
      height: height * bubbleHeightFactor,
      width: width * 1.1,
      top,
      left,
    });

    canvas.add(speech);
    canvas.add(bubble);
    canvas.bringToFront(speech);
    canvas.requestRenderAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  React.useEffect(() => {
    if (!canvas) return;
    const speech = getCanvasObjectById("speech_text", canvas);
    const bubble = getCanvasObjectById("speech_bubble", canvas);
    if (speech && bubble) {
      speech._set("text", text.trim());

      const height = speech.getScaledHeight();
      bubble._set("height", height * bubbleHeightFactor);
      canvas?.renderAll();
    }
  }, [canvas, text]);

  return null;
};
