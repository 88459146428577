import React from "react";
import backGround from "../assets/poster_background.jpg";
import brush from "../assets/brush-1.svg";
import { fabric } from "fabric";
import { DEFAULT_CANVAS_DIMENSION } from "./Poster";
import { lockedObject } from "../utils/createLockedObject";

interface Props {
  canvas: fabric.Canvas | null;
}

// https://codesandbox.io/s/8k7kvwqx70?file=/src/Image.js:410-453
export const BackGround: React.FC<Props> = ({ canvas }) => {
  React.useEffect(() => {
    if (!canvas) return;

    fabric.Image.fromURL(
      brush,
      (img) => {
        img.scaleToWidth(canvas.width || DEFAULT_CANVAS_DIMENSION.width);
        canvas.add(img);
        canvas.sendToBack(img);
      },
      {
        top: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.77,
        left: -1,
        hasControls: false,
      }
    );
    const rect = new fabric.Rect({
      ...lockedObject,
      height: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.2,
      width: (canvas.width || 0) + 10,
      fill: "white",
      top: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.8,
      left: -1,
    });
    canvas.add(rect);

    fabric.Image.fromURL(
      backGround,
      (img) => {
        img.scaleToHeight(canvas.height || DEFAULT_CANVAS_DIMENSION.height);
        img.scaleToWidth(canvas.width || DEFAULT_CANVAS_DIMENSION.width);
        canvas.add(img);
        canvas.sendToBack(img);
      },
      {
        top: 0,
        left: 0,
        hasControls: false,
      }
    );
  }, [canvas]);

  return null;
};
