import React from "react";
import { fabric } from "fabric";
import flag from "../assets/flagEnd.svg";
import pig from "../assets/varken.png";
import { DEFAULT_CANVAS_DIMENSION } from "./Poster";
import { getCanvasObjectById } from "../utils/getObject";
import { lockedObject } from "../utils/createLockedObject";

interface Props {
  text: string;
  canvas: fabric.Canvas | null;
}

// https://codesandbox.io/s/8k7kvwqx70?file=/src/Image.js:410-453
export const Flag: React.FC<Props> = ({ text, canvas }) => {
  React.useEffect(() => {
    if (!canvas) return;
    const width = (canvas.width || DEFAULT_CANVAS_DIMENSION.width) * 0.4;
    const bannerHeight =
      (canvas?.height || DEFAULT_CANVAS_DIMENSION.height) / 16;
    const flagWidth = 60;
    const boxWidth = width + flagWidth;
    const bg = new fabric.Rect({
      fill: "#fe9900",
      originX: "center",
      originY: "center",
      width,
      height: bannerHeight,
      left: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) - width,
    });
    const text = new fabric.Text(
      "Bekend van de Benefiet\nquiz voor de horeca",
      {
        ...lockedObject,
        fontFamily: "Open Sans",
        fontSize: (canvas.height || DEFAULT_CANVAS_DIMENSION.height) / 55,
        originX: "center",
        originY: "center",
        textAlign: "justify",
        fill: "white",
        left: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) - width,
      }
    );
    const group = new fabric.Group([bg, text], {
      ...lockedObject,
      // @ts-ignore
      id: "flag_group",
      left: (canvas.width || DEFAULT_CANVAS_DIMENSION.width) - width - 30,
      top:
        (canvas.height || DEFAULT_CANVAS_DIMENSION.height) * 0.12 -
        bannerHeight,
      width: boxWidth,
      height: 150,
    });
    fabric.Image.fromURL(
      flag,
      (img) => {
        img.scaleToHeight(bannerHeight);
        group.add(img);
      },
      {
        ...lockedObject,
        left: -95,
        top: -19,
      }
    );
    const money = new fabric.Text("€ 821.005,-", {
      ...lockedObject,
      fontFamily: "Open Sans",
      fontSize: 10,
      fill: "black",
      charSpacing: -125,
      top: 47,
      left: -18,
    });

    fabric.Image.fromURL(
      pig,
      (img) => {
        img.scaleToHeight(bannerHeight * 1.5);
        group.add(img);
        group.add(money);
      },
      {
        ...lockedObject,
        left: -50,
        top: 15,
      }
    );

    canvas.add(group);
    canvas.bringToFront(group);
    canvas.requestRenderAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  React.useEffect(() => {
    if (!canvas) return;

    const titleText = getCanvasObjectById("title_text", canvas);
    const flagGroup = getCanvasObjectById("flag_group", canvas);
    if (titleText && flagGroup) {
      const coords = titleText.getCoords();
      const rect = titleText.getScaledHeight();
      flagGroup._set("top", coords[3].y + rect - 20);
      canvas?.renderAll();
    }
  }, [canvas, text]);

  return null;
};
